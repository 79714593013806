<template>
  <v-app-bar
    :class="$vuetify.breakpoint.smAndUp ? 'mt-4 rounded-lg' : ''"
    height="auto"
    :src="hasImage ? currentProduct.image : ''"
  >
    <template v-if="hasImage" v-slot:img="{ props }">
      <v-img
        class="product-image"
        v-bind="props"
        gradient="to top, rgba(0,0,0,.6), rgba(0,0,0,.8)"
      ></v-img>
    </template>
    <v-container fluid class="py-5 px-0">
      <v-row class="pb-4" justify="start" align="center">
        <v-btn @click="goBack" color="transparent" icon fab elevation="0" small>
          <v-icon :color="hasImage ? 'white' : 'accent'"
            >mdi-chevron-left</v-icon
          >
        </v-btn>
      </v-row>
      <v-col :class="hasImage ? 'white--text' : ''" class="pl-8 pt-6" cols="12">
        <v-row>
          <v-col class="pa-0" cols="8">
            <div class="text-h5 wrap-text">
              {{ currentProduct ? currentProduct.name : undefined }}
            </div>
          </v-col>
        </v-row>
        <v-row class="py-4">
          <v-col class="pa-0" cols="8">
            <div class="text-subtitle-2 font-weight-light wrap-text">
              {{ currentProduct ? currentProduct.description : undefined }}
            </div>
          </v-col>
        </v-row>
        <v-row
          v-if="
            currentProductOriginDetails && currentProductOriginDetails.price
          "
          class="pb-4"
        >
          <div class="text-h6">$ {{ currentProductOriginDetails.price }}</div>
        </v-row>
      </v-col>
    </v-container>
  </v-app-bar>
</template>

<script>
import getCurrentProduct from "@/mixins/getCurrentProduct";

export default {
  mixins: [getCurrentProduct],
  methods: {
    goBack() {
      this.$store.dispatch("currentProduct/cleanCurrentProduct");
      return this.$router.push({
        path: this.returnPath,
        query: this.$route.query,
      });
    },
  },
  computed: {
    hasImage() {
      if (this.currentProduct?.image) {
        return true;
      }
      return false;
    },
    originName() {
      return this.$store.getters["menu/originName"];
    },
    loadingContent() {
      return this.$store.state.menu.loading;
    },
    routeMeta() {
      if (typeof this.$route.meta === "function") {
        return this.$route.meta();
      }
      return this.$route.meta;
    },
    returnPath() {
      return this.routeMeta.appBar.returnPath;
    },
  },
};
</script>

<style scoped>
.wrap-text {
  overflow-wrap: break-word;
}
.product-image {
  height: 100%;
}
</style>
